import DragAndDrop from "./DragAndDrop";

const UploadFile = () => {
  return (
    <div>
        <DragAndDrop></DragAndDrop>
    </div>
  );

};

export default UploadFile;
